// Ui
import Modal from 'ui3/Modal/Modal';
import MillionsIcon from 'ui3/MillionsIcon/MillionsIcon';

import styles from './FiltersToggle.module.scss';

type FiltersToggleProps = {
  filtersModalIsVisible: boolean;
  toggleFiltersVisibility: () => void;
  children?: JSX.Element;
};

const FiltersToggle = ({
  filtersModalIsVisible,
  toggleFiltersVisibility,
  children,
}: FiltersToggleProps) => {
  return (
    <>
      <button
        className={styles.filterToggleButton}
        onClick={toggleFiltersVisibility}
        type="button"
      >
        <span className={styles.filterToggleButtonTitle}>Filter</span>
        <MillionsIcon
          name="filterOutline"
          className={styles.filterToggleButtonIcon}
        />
      </button>

      <Modal
        size="large"
        className={styles.filtersModal}
        open={filtersModalIsVisible}
        onClose={toggleFiltersVisibility}
        focusTrapped={false}
      >
        {children}
      </Modal>
    </>
  );
};

export default FiltersToggle;
